// Chakra Imports
import moment from "moment";
import { BiDollar } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Tr,
	Th,
	Td,
	Tab,
	Img,
	Box,
	Tabs,
	Icon,
	Tbody,
	Modal,
	Flex,
	Input,
	Thead,
	Text,
	Button,
	Table,
	Slider,
	Heading,
	Spinner,
	useToast,
	InputGroup,
	TabPanels,
	TabList,
	TabPanel,
	Progress,
	ModalBody,
	SliderMark,
	SliderTrack,
	SliderThumb,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	TableContainer,
	ModalCloseButton,
	InputLeftElement,
	CircularProgress,
	SliderFilledTrack,
	CircularProgressLabel,
} from "@chakra-ui/react";

// Component Imports
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import moneyGiff from "../../../images/genz/goal/moneyGiff.gif";
import { FaChevronRight, FaCaretDown } from "react-icons/fa";
import eventStar from "../../../images/genz/goal/event_star.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import progressCompleteBg from "../../../images/genz/goal/progess-bg.png";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
	syThemeBgGreenColor,
} from "../../../constants/color";

// Api Imports
import {
	createGoalEvent,
	fetchSelectedGoalPlan,
} from "../../../services/goalServices";

function GoalEvent() {
	const toast = useToast();
	const navigate = useNavigate();
	const { state } = useLocation();
	const createdGoalDetails = state?.data;
	const [loading, isloading] = useState(false);
	const goalPlanningId = state?.goalPlanningId;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [saveSliderValue, setSaveSliderValue] = useState(1);
	const [goalCompletedFlag, setGoalCompletedFlag] = useState(false);

	const todayDate = new Date();
	const [date, setDate] = useState(new Date());
	const [goalAmount, setGoalAmount] = useState();
	const [selectedGoalData, setSelectedGoalData] = useState([]);
	const [highlightedDates, setHighlightedDates] = useState([]);

	const onChange = date => {
		setDate(date);
	};

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (selectedGoalData?.goal_plan[0]?.goal_tags?.length) {
			const goalHastag =
				selectedGoalData?.goal_plan[0]?.goal_tags?.split(",");
			return goalHastag.map((data, index) => {
				return (
					<Text
						key={data + index}
						bg={syBlack}
						mr="3"
						mb="0.5rem"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						{data}
					</Text>
				);
			});
		}
	};

	useEffect(() => {
		fetchGoalDetails();
	}, [goalPlanningId]);

	// The fetchGoalDetails function remains the same
	const fetchGoalDetails = () => {
		isloading(true);
		let obj = {
			goal_planning_uuid: goalPlanningId,
		};

		return fetchSelectedGoalPlan(obj)
			.then(res => {
				isloading(false);
				if (res?.result) {
					setGoalAmount(
						res?.data[0]?.goal_plan[0]?.goal_event_amount
					);
					setGoalCompletedFlag(
						res?.data[0]?.goal_plan[0]?.goal_completed
					);
					setSelectedGoalData(res?.data[0]);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isloading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const handleSlide = value => {
		setSaveSliderValue(value);
	};

	useEffect(() => {
		if (saveSliderValue === 100) {
			saveEvent();
		}
	}, [saveSliderValue]);

	const saveEvent = () => {
		let obj = {
			goal_uuid: createdGoalDetails?.goal_type_uuid,
			goal_planning_uuid: goalPlanningId,
			goal_amount: goalAmount,
			goal_amount_currency: createdGoalDetails?.goal_currency,
			event_source: "self",
			event_signal: null,
			event_security: null,
			event_signature: null,
			event_source_details: "Paid by Self",
		};

		createGoalEvent(obj)
			.then(res => {
				if (res?.result) {
					setSaveSliderValue(0);
					toast(toastFunctionToaster(res?.message, "success"));
					fetchGoalDetails()
						.then(() => {
							onOpen();
						})
						.catch(err => {
							toast(
								toastFunctionToaster(
									"Failed to fetch goal details",
									"error"
								)
							);
						});
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				// Handle errors in createGoalEvent call
				toast(toastFunctionToaster(err?.message, "error"));
			});
	};

	const renderEventData = () => {
		if (selectedGoalData?.goal_events?.[0]?.events?.length > 0) {
			// Render the list of events in a table
			return (
				<TableContainer
					px="0"
					h="200px"
					overflow={"hidden"}
					overflowY={"scroll"}
				>
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>Date</Th>
								<Th>Amount</Th>
								<Th>Source</Th>
							</Tr>
						</Thead>
						<Tbody>
							{selectedGoalData.goal_events[0].events.map(
								(event, index) => (
									<Tr key={index}>
										<Td>
											{event?.datetime_created
												? moment(
														event?.datetime_created
												  )?.format("DD/MM/YYYY")
												: "-"}
										</Td>
										<Td>{event.goal_amount}</Td>
										<Td>{event.event_source}</Td>
									</Tr>
								)
							)}
						</Tbody>
					</Table>
				</TableContainer>
			);
		} else {
			// No events found
			return (
				<Text bg={syGrey} p="10px" borderRadius={"5px"}>
					No record found
				</Text>
			);
		}
	};

	// Todo:Calculate Progress
	const calculateProgress = () => {
		const savedAmount = selectedGoalData?.goal_events?.[0]?.saved_amount;
		const targetAmount =
			selectedGoalData?.goal_plan?.[0]?.goal_target_amount;
		const progress = (savedAmount / targetAmount) * 100;

		return progress.toFixed(2);
	};

	useEffect(() => {
		if (selectedGoalData?.goal_events?.[0]?.events?.length) {
			const dates =
				selectedGoalData?.goal_events?.[0]?.events?.map(
					event => new Date(event.datetime_created)
				) || [];

			setHighlightedDates(dates);
		}
	}, [selectedGoalData]);

	// Todo:Function to check if the date is in the highlighted dates array
	const isHighlighted = date => {
		return highlightedDates.some(
			highlightedDate =>
				date.getFullYear() === highlightedDate.getFullYear() &&
				date.getMonth() === highlightedDate.getMonth() &&
				date.getDate() === highlightedDate.getDate()
		);
	};

	// Custom tileContent to highlight specific dates

	// Todo:Custom tileContent to highlight specific dates
	const tileContent = ({ date, view }) => {
		if (view === "month" && isHighlighted(date)) {
			return (
				<div
					style={{
						width: "10px",
						margin: "0 auto",
						backgroundColor: "yellow",
						borderRadius: "20px",
						padding: "5px",
						textAlign: "center",
						color: "black",
					}}
				></div>
			);
		}
		return null;
	};

	const calculateDaysRemaining = endDate => {
		const formatedEndDate = moment(endDate)?.format("YYYY/MM/DD");
		const end = new Date(formatedEndDate);
		const timeDifference = end.getTime() - todayDate.getTime();
		// Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
		const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
		return daysRemaining;
	};

	// Todo:Navigate to Goal Completion Dashboard
	const navigateToDashboard = () => {
		navigate("/genz/user-profile");
	};

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			{Object.keys(selectedGoalData)?.length ? (
				<Box px="20px" py="30px">
					<Flex w="100%" justifyContent={"space-between"}>
						<Box>
							<CommonHeader logoutMl={"2rem"} />
						</Box>
						<CommonWidget />
					</Flex>
					<Box
						bg={syThemeBgYellowColor}
						my="1rem"
						borderRadius={"10px"}
					>
						<Heading
							fontWeight={"900"}
							textAlign={"center"}
							py="1.5rem"
							fontSize={"24px"}
						>
							{selectedGoalData?.goal_plan?.[0]?.goal_name}
						</Heading>
					</Box>

					{loading ? (
						<Flex
							alignItems="center"
							justifyContent="center"
							h="50vh"
							color={syBlack}
						>
							<Spinner size="xl" color={syBlack} />
						</Flex>
					) : selectedGoalData?.goal_plan[0]?.goal_tags ? (
						<Flex mt="1rem" borderRadius={"10px"} flexWrap={"wrap"}>
							{renderHashTag()}
						</Flex>
					) : null}

					<Box
						bg={syWhite}
						textAlign={"center"}
						px="10px"
						py="20px"
						borderRadius={"10px"}
						boxShadow={"md"}
					>
						<Text fontWeight={"600"} fontSize={"20px"} mb="3">
							Total Savings Goal
						</Text>

						<Text
							fontWeight={"600"}
							fontSize={"20px"}
							color={syThemeBgBlueColor}
						>
							${" "}
							{selectedGoalData?.goal_plan[0]?.goal_target_amount}
						</Text>
						<Text
							fontWeight="600"
							fontSize="18px"
							mb="3"
							color={
								calculateDaysRemaining(
									selectedGoalData?.goal_plan?.[0]
										?.goal_completion_datetime
								) < 0
									? "red.300"
									: "green.300"
							}
							border="1px"
							borderRadius="10px"
							p="10px"
							mt="3"
						>
							{(() => {
								const daysRemaining = calculateDaysRemaining(
									selectedGoalData?.goal_plan?.[0]
										?.goal_completion_datetime
								);
								return daysRemaining < 0
									? `You are ${daysRemaining} days over to complete the goal`
									: `You have ${daysRemaining} days to complete this goal`;
							})()}
						</Text>
					</Box>

					<Box
						bg={syWhite}
						textAlign={"center"}
						px="10px"
						py="20px"
						mt="3"
						borderRadius={"10px"}
					>
						<Text
							textAlign={"center"}
							fontWeight={"600"}
							fontSize={"20px"}
							mb="1rem"
						>
							Rewards
						</Text>
						<Flex mt="3" justifyContent={"space-around"}>
							<Box
								w="40%"
								textAlign={"center"}
								borderWidth={"1px"}
								borderRadius={"10px"}
								p="2%"
							>
								<Img src={eventStar} mx="auto" />
								<Text
									fontWeight={"600"}
									color={syThemeBgBlueColor}
								>
									x
									{
										selectedGoalData?.goal_events[0]
											?.event_stars
									}
								</Text>
								<Text fontWeight={"600"}>Syval Stars</Text>
								<Text>Per Save</Text>
							</Box>
							<Box
								w="40%"
								textAlign={"center"}
								borderWidth={"1px"}
								borderRadius={"10px"}
								p="2%"
							>
								<Box position={"relative"}>
									<Img
										w="80px"
										h="80px"
										borderRadius={"5px"}
										mx="auto"
										src={
											selectedGoalData?.goal_plan[0]
												?.goal_reward_icon
												? selectedGoalData?.goal_plan[0]
														?.goal_reward_icon
												: standImg
										}
									></Img>
									{selectedGoalData?.goal_plan[0]
										?.goal_reward_icon ? null : (
										<Box
											position={"absolute"}
											top="30px"
											left={"38%"}
										>
											<Img w="50%" src={lockImg}></Img>
										</Box>
									)}
								</Box>
								<Text fontWeight={"600"}>I love concert</Text>
								<Text fontWeight={"600"}>Badge</Text>
							</Box>
						</Flex>
						<Text
							textAlign={"center"}
							fontWeight={"600"}
							textDecoration={"underline"}
							mt="1rem"
						>
							Edit
						</Text>
					</Box>

					<Box
						bg={syThemeBgBlueColor}
						textAlign={"center"}
						px="10px"
						py="20px"
						mt="3"
						borderRadius={"10px"}
					>
						<Text
							color={syWhite}
							fontSize={"20px"}
							fontWeight={"600"}
						>
							Save Now
						</Text>
						<Flex alignItems={"center"} justifyContent={"center"}>
							<Text
								color={syThemeBgGreenColor}
								fontWeight={"600"}
								fontSize={"20px"}
							>
								$
								{
									selectedGoalData?.goal_plan[0]
										?.goal_event_amount
								}
							</Text>{" "}
							<Text
								color={"#fff"}
								ml="3"
								fontWeight={"600"}
								fontSize={"20px"}
							>
								Per{" "}
								{
									selectedGoalData?.goal_plan[0]
										?.goal_duration_unit
								}
							</Text>
							<Box>
								<FaCaretDown
									color={"#fff"}
									ml="3"
									fontWeight={"600"}
									fontSize={"20px"}
								/>
							</Box>
						</Flex>
						<InputGroup
							border="none"
							bg={syWhite}
							borderRadius={"10px"}
							h="50px"
							my="1rem"
						>
							<InputLeftElement pointerEvents="none" top="5px">
								<BiDollar />
							</InputLeftElement>
							<Input
								type="number"
								fontWeight={"bold"}
								color={syBlack}
								border="0"
								_hover={{ boxShadow: "none" }}
								textAlign={"center"}
								h="50px"
								value={goalAmount}
								onChange={e => {
									setGoalAmount(e.target.value);
								}}
								//isReadOnly
							/>
						</InputGroup>
						<Slider
							w="90%"
							id="slider"
							defaultValue={goalAmount}
							min={10}
							max={
								selectedGoalData?.goal_plan[0]
									?.goal_target_amount
							}
							onChange={val => setGoalAmount(val)}
							colorScheme="blue"
							my="1rem"
							mx="auto"
						>
							{/* Slider Marks */}
							<SliderMark
								value={10}
								mt="4"
								ml="0"
								color={syWhite}
								fontSize="12px"
								fontWeight="700"
							>
								$10
							</SliderMark>

							<SliderMark
								value={
									selectedGoalData?.goal_plan[0]
										?.goal_target_amount
								}
								mt="4"
								ml="-8"
								color={syWhite}
								fontSize="12px"
								fontWeight="700"
								textAlign="center"
							>
								$
								{
									selectedGoalData?.goal_plan[0]
										?.goal_target_amount
								}
							</SliderMark>

							{/* Adjust Slider Track */}
							<SliderTrack
								h="15px"
								borderRadius="10px"
								mx="auto"
								w="100%"
							>
								<SliderFilledTrack />
							</SliderTrack>

							<SliderThumb />
						</Slider>

						<Box
							p={6}
							w="100%"
							textAlign="center"
							position={"relative"}
						>
							<Slider
								aria-label="custom-slide-to-save"
								value={saveSliderValue}
								onChange={handleSlide}
								min={1}
								max={100}
								focusThumbOnChange={false}
								_placeholder={"Slide to Save"}
								isDisabled={
									saveSliderValue === 100 ? true : false
								}
							>
								{/* Custom Slider Track */}
								<SliderTrack
									bgGradient="linear(90deg, #5F67EC 0%, #DAF66F 100%)"
									borderRadius="full"
									h="40px"
									boxShadow="lg"
								>
									<SliderFilledTrack bg="transparent" />
								</SliderTrack>

								{/* Custom Slider Thumb */}
								<SliderThumb
									boxSize={10}
									bg="white"
									boxShadow="lg"
									_focus={{ boxShadow: "outline" }}
								>
									<Icon as={FaChevronRight} w={5} h={5} />
								</SliderThumb>
							</Slider>
							{/* {saveSliderValue > 80 ? null : ( */}
							<Text
								color={syWhite}
								position={"absolute"}
								top="35%"
								left={"40%"}
								opacity={1 - saveSliderValue / 100}
								transition="opacity 0.2s ease-out"
							>
								Slide To Save
							</Text>
							{/* )} */}
						</Box>
					</Box>

					<Box
						bg={syWhite}
						textAlign={"center"}
						px="10px"
						py="20px"
						mt="3"
						borderRadius={"10px"}
					>
						<Text
							textAlign={"center"}
							fontWeight={"600"}
							fontSize={"20px"}
						>
							My Progress
						</Text>

						<Text
							color={syThemeBgBlueColor}
							textAlign={"center"}
							fontWeight={"600"}
							fontSize={"20px"}
							my="3"
						>
							${selectedGoalData?.goal_events[0]?.saved_amount} of
							$
							{selectedGoalData?.goal_plan[0]?.goal_target_amount}
						</Text>
						<Progress
							w="100%"
							h="15px"
							bg={syGrey}
							borderRadius={"2px"}
							my="0.5rem"
							colorScheme="green"
							value={
								selectedGoalData?.goal_events[0]?.saved_amount
							}
							max={
								selectedGoalData?.goal_plan[0]
									?.goal_target_amount
							}
						/>

						<Flex justifyContent={"center"} mt="1rem">
							<Img src={eventStar} />
							<Text
								color={syThemeBgBlueColor}
								fontWeight={"600"}
								fontSize={"16px"}
								pt="30px"
							>
								x{" "}
								{selectedGoalData?.goal_events[0]?.event_stars}
							</Text>
						</Flex>
					</Box>

					<Box
						p="4"
						bg={syWhite}
						w="100%"
						mt="1rem"
						mb="4rem"
						borderRadius={"10px"}
					>
						<Tabs variant="unstyled" justifyContent="center">
							<TabList>
								<Tab
									_selected={{
										color: "white",
										bg: "green.400",
									}}
								>
									Savings History
								</Tab>
								<Tab
									_selected={{
										color: "white",
										bg: "green.400",
									}}
								>
									Savings Timeline
								</Tab>
							</TabList>
							<TabPanels>
								<TabPanel px="0">
									<Calendar
										onChange={onChange}
										value={date}
										// minDate={new Date()}
										tileContent={tileContent}
									/>
								</TabPanel>
								<TabPanel px="0">{renderEventData()}</TabPanel>
							</TabPanels>
						</Tabs>
					</Box>
				</Box>
			) : null}
			<Modal
				isCentered
				isOpen={isOpen}
				onClose={onClose}
				size={"sm"}
				closeOnOverlayClick={goalCompletedFlag ? false : true}
			>
				<ModalOverlay />
				<ModalContent
					m="5"
					borderRadius="10px"
					bgImage={progressCompleteBg}
					bgRepeat="no-repeat"
					bgSize="cover"
				>
					{goalCompletedFlag ? null : (
						<ModalCloseButton left="7px" bg="#fff" />
					)}
					<ModalBody
						m="10"
						bg="#fff"
						borderRadius={"10px"}
						p="10px"
						py="20px"
						opacity={goalCompletedFlag ? 1 : "0.94"}
					>
						{goalCompletedFlag ? (
							<Box>
								<Heading
									fontSize={"24px"}
									fontWeight={"700"}
									textAlign={"center"}
								>
									Awesome!!!
								</Heading>

								<Heading
									fontSize={"25px"}
									fontWeight={"900"}
									textAlign={"center"}
									mt="4"
								>
									You just saved
								</Heading>
								<Heading
									fontSize={"24px"}
									fontWeight={"700"}
									textAlign={"center"}
									mt="4"
									color={syThemeBgBlueColor}
								>
									$
									{
										selectedGoalData?.goal_events?.[0]
											?.saved_amount
									}
								</Heading>
								<Flex justifyContent={"center"} mt="1rem">
									<Img src={eventStar} />
									<Text
										color={syThemeBgBlueColor}
										fontWeight={"600"}
										fontSize={"16px"}
										pt="30px"
									>
										x{" "}
										{
											selectedGoalData?.goal_events?.[0]
												?.event_stars
										}
									</Text>
								</Flex>
								<Box>
									<Img src={moneyGiff} alt="money giff" />
								</Box>
								<Box textAlign={"center"}>
									<Button
										borderRadius={"30px"}
										boxShadow={"md"}
										color={syThemeBgBlueColor}
										bg={syThemeBgYellowColor}
										_hover={{ boxShadow: "none" }}
										onClick={() => {
											navigateToDashboard();
										}}
									>
										Continue
									</Button>
								</Box>
							</Box>
						) : (
							<Box>
								<Heading
									fontSize={"24px"}
									fontWeight={"700"}
									textAlign={"center"}
								>
									Your Rich Life is <br />
									on the Way!
								</Heading>
								<Heading
									mt="6"
									fontSize={"18px"}
									fontWeight={"600"}
									textAlign={"center"}
								>
									Total Progress
								</Heading>
								<Text
									color={syThemeBgBlueColor}
									textAlign={"center"}
									fontWeight={"600"}
									fontSize={"20px"}
									my="3"
								>
									$
									{
										selectedGoalData?.goal_events?.[0]
											?.saved_amount
									}{" "}
									of $
									{
										selectedGoalData?.goal_plan?.[0]
											?.goal_target_amount
									}
								</Text>
								<Box mt="5" textAlign={"center"}>
									<CircularProgress
										value={calculateProgress()}
										size="120px"
										borderRadius="10px"
										color={syThemeBgGreenColor}
										trackColor="#7668D3"
									>
										<CircularProgressLabel
											fontWeight={"bold"}
											fontSize={"20px"}
											color={syThemeBgBlueColor}
										>
											{calculateProgress()} %
										</CircularProgressLabel>
									</CircularProgress>
								</Box>
								<Heading
									fontSize={"24px"}
									textAlign={"center"}
									color={syThemeBgBlueColor}
									my="3"
								>
									$ {goalAmount}
								</Heading>
								<Box textAlign={"center"}>
									<Button
										borderRadius={"30px"}
										boxShadow={"md"}
										color={syThemeBgBlueColor}
										bg={syThemeBgYellowColor}
										_hover={{ boxShadow: "none" }}
										onClick={onClose}
									>
										Check my progress
									</Button>
								</Box>
							</Box>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default GoalEvent;
