// React Chakra Imports
import React from "react";
import {
	Tr,
	Th,
	Td,
	Tab,
	Img,
	Tabs,
	Text,
	Flex,
	Box,
	Table,
	Tbody,
	Thead,
	Modal,
	TabList,
	TabPanels,
	TabPanel,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import lockImg from "../../../../images/genz/goal/lock.png";
import standImg from "../../../../images/genz/goal/stand.png";
import eventStar from "../../../../images/genz/goal/event_star.png";
import { syThemeBgBlueColor, syWhite } from "../../../../constants/color";

function DreamLifeSavedEvent({ completedData, open, onClose }) {
	// Todo:Data not available to render event
	return (
		<Modal isOpen={open} onClose={onClose} size="sm">
			<ModalOverlay />
			<ModalContent m="5">
				<ModalCloseButton />
				<ModalBody>
					<Text p="10px" color={syThemeBgBlueColor}>
						For{" "}
						<Text as="span" fontWeight={"700"}>
							{completedData?.goal_name}
						</Text>{" "}
						you saved total $
						<Text as="span" fontWeight={"700"}>
							{completedData?.saved_amount} in{" "}
						</Text>
						<Text as="span" fontWeight={"700"}>
							{completedData?.goal_duration_days}
						</Text>
						<Text as="span" fontWeight={"700"}>
							{completedData?.goal_duration_unit === "daily"
								? "day"
								: completedData?.goal_duration_unit}{" "}
						</Text>
						through{" "}
						<Text as="span" fontWeight={"700"}>
							{completedData?.saved_event}
						</Text>{" "}
						saving events
					</Text>

					<Box
						bg={syWhite}
						textAlign={"center"}
						borderRadius={"10px"}
						my="1em"
					>
						<Text
							textAlign={"center"}
							fontWeight={"600"}
							fontSize={"20px"}
							mb="1rem"
						>
							Rewards
						</Text>
						<Flex mt="3" justifyContent={"space-around"}>
							<Box
								w="40%"
								textAlign={"center"}
								borderWidth={"1px"}
								borderRadius={"10px"}
								p="1%"
							>
								<Img src={eventStar} mx="auto" />
								<Text
									fontWeight={"600"}
									color={syThemeBgBlueColor}
								>
									x{completedData?.event_stars}
								</Text>
								<Text fontWeight={"600"}>Syval Stars</Text>
								<Text>Per Save</Text>
							</Box>
							<Box
								w="40%"
								textAlign={"center"}
								borderWidth={"1px"}
								borderRadius={"10px"}
								p="1%"
							>
								<Box position={"relative"}>
									<Img
										w="80px"
										h="80px"
										borderRadius={"5px"}
										mx="auto"
										src={
											completedData?.goal_reward_icon
												? completedData?.goal_reward_icon
												: standImg
										}
									></Img>
									{completedData?.goal_reward_icon ? null : (
										<Box
											position={"absolute"}
											top="30px"
											left={"38%"}
										>
											<Img w="50%" src={lockImg}></Img>
										</Box>
									)}
								</Box>
								<Text fontWeight={"600"}>I love concert</Text>
								<Text fontWeight={"600"}>Badge</Text>
							</Box>
						</Flex>
					</Box>

					<Tabs variant="unstyled" justifyContent="center">
						<TabList>
							<Tab
								_selected={{
									color: "white",
									bg: "green.400",
								}}
							>
								Savings Timeline
							</Tab>
							<Tab
								_selected={{
									color: "white",
									bg: "green.400",
								}}
							>
								Tab 2
							</Tab>
						</TabList>

						<TabPanels>
							<TabPanel px="0">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>Date</Th>
											<Th>Amount</Th>
											<Th>Source</Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td>28/09/2024</Td>
											<Td>50</Td>
											<Td>self</Td>
										</Tr>
										<Tr>
											<Td>28/09/2024</Td>
											<Td>50</Td>
											<Td>self</Td>
										</Tr>
									</Tbody>
								</Table>
							</TabPanel>
							<TabPanel px="0">
								<Text>Tab 2</Text>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default DreamLifeSavedEvent;
