export const serviceAPIs = (urlVar = null) => {
	return {
		login: "/v1/api/user/login",
		tokenApi: `/v1/api/token`,
		registerUser: "/v1/api/user/register_syval_user",
		verifyUser: "/v1/api/user/verify_email_code",
		regenerateCode: "/v1/api/user/regenerate_email_code",
		forgotPassword: "/v1/api/reset_password",
		changeDefaultPassword: "/v1/api/update_default_password",
		logout: "/v1/api/user/logout",
		addBetaEmail: "/v1/api/user/beta_request/add",
		verifyBetaEmail: "/v1/api/user/beta_request/verify",
		getBetaUsers: "/v1/api/admin/get_beta_users",
		usersWithPlan: "/v1/api/admin/goal/plan/users_with_plans",
		userGoalPlanList: "/v1/api/admin/goal/plan/listing",
		goalPlanEvent: "/v1/api/admin/goal/plan/goal_events",
		// goal api
		goalList: "/v1/api/admin/goal/listing",
		addGoalDetails: "/v1/api/admin/goal/create",
		editGoalDetails: "/v1/api/admin/goal/update",
		enableDisableGoal: "/v1/api/admin/goal/manage", //Todo:Api not available just added dummy api
		addGoalEvent: "/v1/api/user/goal/event/create",
		selectedGoalData: "/v1/api/user/goal/plan/selected",
		goalRewardIcon: "/v1/api/user/goal/reward_icons",
		uploadGoalHeadshot: "/v1/api/admin/goal/resources",
		uploadGoalResourceIcon: "/v1/api/admin/resource/content/upload",
		// user goal api
		fetchUserGoal: "/v1/api/user/goal/listing",
		createGoalPlan: "/v1/api/user/goal/plan/create",
		updateGoalPlan: "/v1/api/user/goal/plan/update",
		resourceListData: "/v1/api/admin/resource/get",
		resourceList: "/v1/api/admin/resource/listing",
		// user api
		userList: "/v1/api/admin/user/listing", //Todo:Api not available
		addBaseInfo: "/v1/api/user/add_base_info",
		getBaseInfo: "/v1/api/user/get_base_info",
		enableDisableUser: "/v1/api/admin/user/manage",
		uploadUserProfile: "/v1/api/user/update_headshot",
		fetchUserDetails: "v1/api/user/personal_info",
		updateUserData: "v1/api/user/update_info",
		updateCurrentPassword: "/v1/api/user/update_password",
		uploadReward: "/v1/api/user/resource/content/upload",
		// dashboard api
		fetchDashboardData: "/v1/api/user/dashboard/info",
		goalListWithResource: "/v1/api/admin/goal/listing_with_resources",
		fetchDreamLifeDashboardData: "/v1/api/user/dashboard/completed",
		fetchDreamLifeDashboarActivedData: "/v1/api/user/dashboard/active",
		// reward store api
		addNewStoreItem: "/v1/api/admin/store/item/add",
		manageStoreStatus: "/v1/api/admin/store/item/manage",
		updateStoreItem: "/v1/api/admin/store/item/update",
		listStoreRewardClass: "/v1/api/admin/store/item/class",
		listStoreRewardGroup: "/v1/api/admin/store/item/groups",
		listStoreRewardList: "/v1/api/admin/store/item/listing",
	};
};
