import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const uploadUserProfileImage = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadUserProfile, data, config);
};

export const addBaseInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addBaseInfo, data, config);
};

export const getBaseInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getBaseInfo, data, config);
};

export const getUserDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchUserDetails, data, config);
};

export const updateUserDetails = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.put(serviceAPIs().updateUserData, data, config);
};

export const getDashboardData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().fetchDashboardData, data, config);
};

export const updatePassword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(
		serviceAPIs().updateCurrentPassword,
		data,
		config
	);
};

export const uploadRewardIconImg = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadReward, data, config);
};

export const getDreamLifeDashboardData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().fetchDreamLifeDashboardData,
		data,
		config
	);
};

export const getDreamLifeDashboardActiveData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().fetchDreamLifeDashboarActivedData,
		data,
		config
	);
};
