// React Chakra Imports
import React, { useState } from "react";
import {
	Box,
	Flex,
	Tabs,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";

// Component Imports
import Profile from "./profile/Profile";
import DreamLife from "./dreamLife/DreamLife";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import CommonWidget from "../../common/CommonWidget";
import {
	syThemeBgBlueColor,
	syThemeBgGreenColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../../constants/color";

function UserProfile() {
	const [tabName, setTabName] = useState("dream");
	return (
		<Box
			bg={tabName === "dream" ? syThemeBgGreenColor : syThemeBgBlueColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
			minH="940px"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>

				<Box px="10px" py="30px">
					<Tabs>
						<TabList>
							<Tab
								textAlign={"center"}
								fontWeight={"bold"}
								fontSize={"18px"}
								pl="0"
								w="50%"
								onClick={() => {
									setTabName("dream");
								}}
								color={
									tabName === "dream"
										? syThemeBgBlueColor
										: syWhite
								}
								_focus={{ boxShadow: "none" }}
							>
								My Dream Life
							</Tab>
							<Tab
								textAlign={"center"}
								fontWeight={"bold"}
								fontSize={"18px"}
								w="50%"
								onClick={() => {
									setTabName("profile");
								}}
								_selected={{
									color: syThemeBgYellowColor,
									fontWeight: 600,
									borderBottomColor: syThemeBgYellowColor,
								}}
								_focus={{ boxShadow: "none" }}
							>
								My profile
							</Tab>
						</TabList>

						<TabPanels>
							<TabPanel px="0">
								<DreamLife tabValue={tabName} />
							</TabPanel>
							<TabPanel px="0">
								<Profile tabValue={tabName} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
				userRoute="/genz/user-profile"
			/>
		</Box>
	);
}

export default UserProfile;
