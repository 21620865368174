// Chakra Imports
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import {
	Img,
	Box,
	Flex,
	Text,
	Input,
	Button,
	Heading,
	useToast,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";

// Component Imports
import backIcon from "../../../images/common/back.png";
import divider from "../../../images/common/divider.png";
import googleIcon from "../../../images/common/googleIcon.png";
import facebookIcon from "../../../images/common/facebookIcon.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	saveAuthToken,
	saveEmailId,
	savegroupId,
	saveUserName,
} from "../../../utils/localStorageIndex";
import {
	syBlack,
	syWhite,
	syThemeLabelColor,
	syThemeBgGreenColor,
} from "../../../constants/color";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";

// Api Imports
import {
	fetchSystemToken,
	systemLogin,
} from "../../../services/sharedServices";
import { getDashboardData } from "../../../services/userServices";
import { setWidgetData } from "../../../store/actions/topNotificationActions";

function Login() {
	const toast = useToast();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const height = useResponsiveHeight();
	const [error, setError] = useState({});
	const [show, setShow] = useState(false);
	const [loading, isloading] = useState(false);

	const [loginForm, setLoginForm] = useState({
		// email: "avkash@gmail.com",
		// password: "Avkash2020",
		// email: "avkash@mysyval.com",
		// password: "master#123",
		email: "",
		password: "",
	});

	const onUpdateField = e => {
		const nextFormState = {
			...loginForm,
			[e.target.name]: e.target.value,
		};
		setLoginForm(nextFormState);
	};

	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		let fields = loginForm;
		let emailPattern = new RegExp(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		);
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your email.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter a valid email address.";
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter your password.";
		}
		setError(errors);
		return formIsValid;
	};

	const handleSubmit = async event => {
		event.preventDefault();
		if (validateFieldForm()) {
			try {
				let obj = {
					email: loginForm?.email,
					password: loginForm?.password,
				};
				isloading(true);

				const res = await systemLogin(obj);
				isloading(false);

				if (res?.result) {
					const userData = res?.data[0];

					if (userData?.email_verified === false) {
						navigate("/verification", {
							state: { emailId: loginForm?.email },
						});
					} else if (userData?.is_dummy_password === true) {
						navigate("/default_password", {
							state: { emailId: loginForm?.email },
						});
					} else if (
						userData?.email_verified === true &&
						userData?.is_dummy_password === false
					) {
						const formData = new FormData();
						formData.append("grant_type", "");
						formData.append(
							"username",
							loginForm.email.toLowerCase()
						);
						formData.append("password", loginForm.password);
						formData.append("scope", "");
						formData.append("client_id", "");
						formData.append("client_secret", "");

						const tokenRes = await fetchSystemToken(formData);
						if (tokenRes.access_token) {
							saveAuthToken(tokenRes.access_token);
						}

						const groupId = userData.group;
						savegroupId(groupId);
						saveEmailId(userData.email);
						saveUserName(userData.name);

						if (groupId === 200) {
							// navigate("/genz/welcome");
							// navigate("/genz/dasbhboard");
							checkPreferencesData();
						} else if (groupId === 100) {
							navigate("/admin/dashboard");
						}
					}
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			} catch (err) {
				isloading(false);
				toast(toastFunctionToaster("Something Went Wrong!!", "error"));
				console.error(err); // Optionally log the error for debugging
			}
		}
	};

	const checkPreferencesData = () => {
		let obj = {
			key: "string",
		};
		getDashboardData(obj)
			.then(res => {
				if (res?.result) {
					dispatch(setWidgetData(res?.data[0]?.data[1]?.metrics[0]));
					if (
						res?.data[0]?.data?.[0]?.preference &&
						res?.data[0]?.data?.[3]?.active_goal_plans?.length
					) {
						navigate("/genz/dasbhboard");
					} else if (
						!res?.data[0]?.data?.[0]?.preference &&
						!res?.data[0]?.data?.[3]?.active_goal_plans?.length
					) {
						navigate("/genz/welcome");
					} else {
						navigate("/genz/complete");
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	return (
		<Box
			p="30px"
			mx="auto"
			h={height}
			bg={syThemeBgGreenColor}
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
		>
			<Flex w="100%">
				<Img
					src={backIcon}
					onClick={() => {
						navigate("/app");
					}}
				/>
			</Flex>
			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"700"} color={syBlack}>
					Login
				</Heading>
				<Text pt="1rem" fontWeight={"600"} fontSize={"14px"}>
					Hey you’re back, fill in your details to get back in
				</Text>
			</Box>
			<Box pt="2rem">
				<Box>
					<Box
						bg={syWhite}
						borderRadius={"25px"}
						px="15px"
						position={"relative"}
						mt="1rem"
					>
						<Text
							pl="15px"
							fontSize={"14px"}
							position={"absolute"}
							zIndex={"2"}
							top="5px"
							fontWeight={600}
							color={syThemeLabelColor}
						>
							Email
						</Text>
						<Input
							value={loginForm.email}
							name="email"
							onChange={onUpdateField}
							type="text"
							height={"60px"}
							fontWeight={"bold"}
							border={"none"}
							pt="10px"
							_active={{
								boxShadow: "none",
								borderColor: syWhite,
							}}
						></Input>
					</Box>
					<Text color="red" mt="2" fontWeight={"600"}>
						{error?.email}
					</Text>
				</Box>
				<Box>
					<Box
						bg={syWhite}
						borderRadius={"25px"}
						px="15px"
						position={"relative"}
						mt="1rem"
					>
						<Text
							pl="15px"
							fontSize={"14px"}
							position={"absolute"}
							zIndex={"2"}
							top="5px"
							fontWeight={600}
							color={syThemeLabelColor}
						>
							Password
						</Text>
						<InputGroup>
							<Input
								type={show ? "text" : "password"}
								value={loginForm.password}
								name="password"
								onChange={onUpdateField}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									mt="15px"
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShow(!show);
									}}
									bg="none"
									_hover={{ bg: "none" }}
								>
									{show ? (
										<BiHide size="sm" color={syBlack} />
									) : (
										<BiShow size="sm" color={syBlack} />
									)}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2" fontWeight={"600"}>
						{error?.password}
					</Text>
				</Box>
				<Text
					mt="2rem"
					textAlign={"right"}
					fontWeight={"700"}
					cursor={"pointer"}
					onClick={() => {
						navigate("/forgot_password");
					}}
				>
					Forgot Password
				</Text>
				<Box mt="9rem" textAlign={"center"}>
					<Button
						w="40%"
						bg={syBlack}
						color={syWhite}
						_hover={{ bg: syBlack }}
						borderRadius={"50px"}
						size="lg"
						onClick={e => {
							handleSubmit(e);
						}}
						isDisabled={loading ? true : false}
						isLoading={loading ? true : false}
					>
						Login
					</Button>
					<Text
						mt="2rem"
						textAlign={"center"}
						colorScheme={syBlack}
						cursor={"pointer"}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/register");
						}}
					>
						SignUp
					</Text>
				</Box>

				<Box mt="3rem">
					<Img src={divider} mx="auto" />
				</Box>
				<Flex justifyContent={"center"} mt="3rem">
					<Img src={googleIcon} mr="3" />
					<Img src={facebookIcon} mr="3" />
				</Flex>
			</Box>
		</Box>
	);
}

export default Login;
