import { CgAsterisk } from "react-icons/cg";
import React, { useState, useEffect } from "react";
import {
	Flex,
	Input,
	Box,
	Select,
	Heading,
	Button,
	Text,
	useToast,
	Drawer,
	useDisclosure,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports

import { syThemeBgBlueColor, syWhite } from "../../../constants/color";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	createRewardStoreItem,
	editRewardStoreItem,
} from "../../../services/goalServices";

function CreateRewardStore({
	classDetails,
	groupDetails,
	editData,
	isEditClicked,
	triggerCall,
}) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [errors, setErrors] = useState({});
	const [loading, isloading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [rewardItemId, setRewardId] = useState("");
	const [selectedClass, setSelectedClass] = useState("");
	const [selectedGroup, setSelectedGroup] = useState("");
	const [itemPrice, setItemPrice] = useState("");
	const [rewardName, setRewardName] = useState("");
	const [itemDescription, setItemDescription] = useState("");

	// Todo:Update Fields
	useEffect(() => {
		if (Object?.keys(editData)?.length) {
			setRewardId(
				editData?.reward_item_uuid ? editData?.reward_item_uuid : ""
			);
			setSelectedClass(
				editData?.reward_item_class ? editData?.reward_item_class : ""
			);
			setSelectedGroup(
				editData?.reward_item_group ? editData?.reward_item_group : ""
			);
			setItemPrice(
				editData?.reward_item_price ? editData?.reward_item_price : ""
			);
			setRewardName(
				editData?.reward_item_name ? editData?.reward_item_name : ""
			);
			setItemDescription(
				editData?.reward_item_info ? editData?.reward_item_info : ""
			);
			onOpen();
		}
	}, [editData, isEditClicked]);

	// Validation Function
	const validateForm = () => {
		let formErrors = {};
		if (!selectedClass) formErrors.selectedClass = "Class is required";
		if (!selectedGroup) formErrors.selectedGroup = "Group is required";
		if (!rewardName) formErrors.rewardName = "Name is required";
		if (!itemPrice) formErrors.itemPrice = "Price is required";
		if (!itemDescription)
			formErrors.itemDescription = "Description is required";

		setErrors(formErrors);
		return Object.keys(formErrors).length === 0;
	};

	// Handle form submission
	const handleSubmit = () => {
		if (validateForm()) {
			isloading(true);
			let obj = {
				reward_item_class: selectedClass,
				reward_item_group: selectedGroup,
				reward_item_name: rewardName,
				reward_item_price: itemPrice,
				reward_item_info: itemDescription,
			};
			if (rewardItemId) {
				obj["reward_item_uuid"] = rewardItemId;
				editRewardStoreItem(obj)
					.then(res => {
						isloading(false);
						if (res?.result) {
							triggerCall();
							toast(
								toastFunctionToaster(res?.message, "success")
							);
							onClose();
						} else {
							toast(toastFunctionToaster(res?.message, "error"));
						}
						console.log(res);
					})
					.catch(err => {
						isloading(false);
						if (err) {
							toast(toastFunctionToaster(err?.message, "error"));
						}
					});
			} else {
				createRewardStoreItem(obj)
					.then(res => {
						isloading(false);
						if (res?.result) {
							triggerCall();
							toast(
								toastFunctionToaster(res?.message, "success")
							);
							onClose();
						} else {
							toast(toastFunctionToaster(res?.message, "error"));
						}
						console.log(res);
					})
					.catch(err => {
						isloading(false);
						if (err) {
							toast(toastFunctionToaster(err?.message, "error"));
						}
					});
			}
		}
	};

	// Todo:Reset Fiels
	const handleClose = () => {
		onClose();
		setRewardId("");
		setSelectedClass("");
		setSelectedGroup("");
		setItemPrice("");
		setRewardName("");
		setItemDescription("");
	};

	return (
		<Box>
			<Button colorScheme="green" size="xs" onClick={onOpen} ref={btnRef}>
				Add New Item
			</Button>
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					handleClose();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={syThemeBgBlueColor}>
					<DrawerHeader color={syWhite} borderBottomWidth={"0.5px"}>
						{rewardItemId ? "Update" : "Add"} Store Reward
					</DrawerHeader>
					<DrawerCloseButton color="#d7d7d7" />
					<DrawerBody>
						{/* Class Selection */}
						<Box my="1rem">
							<Flex>
								<Heading
									fontSize={"14px"}
									fontWeight={"600"}
									color={syWhite}
									mb="1rem"
								>
									Select Class
								</Heading>
								<CgAsterisk color={"red"} />
							</Flex>
							<Select
								bg="#1c181e"
								color={syWhite}
								border="0px"
								size="md"
								value={selectedClass}
								onChange={e => setSelectedClass(e.target.value)}
								_focus={{
									borderColor: syWhite,
									boxShadow: "none",
								}}
							>
								<option value="">Select Class</option>
								{classDetails?.map(data => (
									<option
										value={data.class_name}
										key={data.class_name}
									>
										{data.class_name}
									</option>
								))}
							</Select>
							{errors.selectedClass && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.selectedClass}
								</Text>
							)}
						</Box>

						{/* Group Selection */}
						<Box my="1rem">
							<Flex>
								<Heading
									fontSize={"14px"}
									fontWeight={"600"}
									color={syWhite}
									mb="1rem"
								>
									Select Group
								</Heading>
								<CgAsterisk color={"red"} />
							</Flex>
							<Select
								bg="#1c181e"
								color={syWhite}
								border="0px"
								size="md"
								value={selectedGroup}
								onChange={e => setSelectedGroup(e.target.value)}
								_focus={{
									borderColor: syWhite,
									boxShadow: "none",
								}}
							>
								<option value="">Select Group</option>
								{groupDetails?.map(data => (
									<option
										value={data.group_name}
										key={data.group_name}
									>
										{data.group_name}
									</option>
								))}
							</Select>
							{errors.selectedGroup && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.selectedGroup}
								</Text>
							)}
						</Box>

						{/* Reward Item Name */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Reward Item Name:
								</Text>
								<CgAsterisk color={"red"} />
							</Flex>
							<Input
								placeholder="Enter Item Name"
								size="sm"
								value={rewardName}
								onChange={e => setRewardName(e.target.value)}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{errors.rewardName && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.rewardName}
								</Text>
							)}
						</Box>

						{/* Reward Item Price */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Reward Item Price:
								</Text>
								<CgAsterisk color={"red"} />
							</Flex>
							<Input
								type="number"
								placeholder="Enter Item Price"
								size="sm"
								value={itemPrice}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								onChange={e => setItemPrice(e.target.value)}
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{errors.itemPrice && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.itemPrice}
								</Text>
							)}
						</Box>

						{/* Description */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Reward Item Info:
								</Text>
								<CgAsterisk color={"red"} />
							</Flex>
							<Input
								placeholder="Enter Item Description"
								size="sm"
								value={itemDescription}
								onChange={e =>
									setItemDescription(e.target.value)
								}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{errors.itemDescription && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.itemDescription}
								</Text>
							)}
						</Box>
					</DrawerBody>

					<DrawerFooter borderTopWidth={"1px"}>
						<Button
							colorScheme="red"
							size="sm"
							mr={3}
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							colorScheme="green"
							size="sm"
							onClick={handleSubmit}
							isDisabled={loading ? true : false}
							isLoading={loading ? true : false}
						>
							{rewardItemId ? "Update" : "Create"}
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default CreateRewardStore;
