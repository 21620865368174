// Chakra - React Imports
import React, { useEffect, useState } from "react";
import {
	Img,
	Box,
	Text,
	Flex,
	Button,
	Drawer,
	Spinner,
	DrawerBody,
	DrawerHeader,
	useDisclosure,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports
import UploadResourceIcon from "./UploadResourceIcon";
import {
	syBgGrey,
	syWhite,
	syThemeBgBlueColor,
} from "../../../constants/color";

// Api Imports
import { fetchResourceGoalListData } from "../../../services/goalServices";

function ResourceList({ goalData }) {
	const [loading, isloading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [resourceGoal, setResourceGoal] = useState([]);

	useEffect(() => {
		fetchResourceListData();
	}, []);

	const fetchResourceListData = () => {
		isloading(true);

		let obj = {
			resource_uuid: goalData?.resource[0]?.resource_uuid,
		};
		fetchResourceGoalListData(obj)
			.then(res => {
				isloading(false);

				if (res?.data?.length) {
					setResourceGoal(res?.data[0]);
				}
			})
			.catch(err => {
				isloading(false);
			});
	};

	const renderGoalDetails = () => {
		if (resourceGoal?.resource_content?.length) {
			return resourceGoal?.resource_content.map((data, index) => {
				return (
					<Img
						h="100px"
						w="100px"
						mr="2"
						border="1px"
						borderRadius={"5px"}
						src={data?.resource_content_url}
					/>
				);
			});
		} else {
			return (
				<Text fontWeight={"600"} color={syWhite}>
					No Resource Images...
				</Text>
			);
		}
	};

	const refreshGoalData = value => {
		if (value === "true") {
			fetchResourceListData();
			handleCloseData();
		}
	};

	const handleCloseData = () => {
		onClose();
	};

	return (
		<Box bg={syBgGrey} p="2" borderRadius={"5px"}>
			{loading ? (
				<Flex alignItems="center" justifyContent="center">
					<Spinner size="sm" p="10px" color={syWhite} />
				</Flex>
			) : (
				<Flex justifyContent="space-between" alignItems="center">
					<Flex alignItems="center">{renderGoalDetails()}</Flex>
					<Button
						size="xs"
						colorScheme="purple"
						onClick={() => {
							onOpen();
						}}
					>
						Add Image
					</Button>
				</Flex>
			)}
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					handleCloseData();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={syThemeBgBlueColor}>
					<DrawerHeader color={syWhite} borderBottomWidth={"0.5px"}>
						Update Resource Icon
					</DrawerHeader>
					<DrawerCloseButton color="#d7d7d7" />
					<DrawerBody>
						<UploadResourceIcon
							resourceGoal={resourceGoal}
							passSuccessFlag={refreshGoalData}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default ResourceList;
