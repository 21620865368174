// React Chakra Imports
import React from "react";
import { IoIosSearch } from "react-icons/io";
import {
	Box,
	Img,
	Text,
	Flex,
	Stack,
	Input,
	InputGroup,
	InputLeftAddon,
} from "@chakra-ui/react";

// Component Imports
import rating from "../../../../images/genz/rating.png";
import CatImg from "../../../../images/genz/goal/store/cat.png";
import BedImg from "../../../../images/genz/goal/store/bed.png";
import ChairImg from "../../../../images/genz/goal/store/chair.png";
import PlantImg from "../../../../images/genz/goal/store/plant.png";
import { syThemeBgBlueColor, syWhite } from "../../../../constants/color";
import KangarooImg from "../../../../images/genz/goal/store/kangaroo.png";
import PlaystationImg from "../../../../images/genz/goal/store/playstation.png";

function DreamShop() {
	const itemArr = [
		{ name: "Playstation", amount: "400", img: PlaystationImg },
		{ name: "Australian Kangaroo", amount: "2,500", img: KangarooImg },
		{ name: "White Cat", amount: "1,400", img: CatImg },
		{ name: "Brown Queen Size Bed", amount: "500", img: BedImg },
		{ name: "Gaming Chair", amount: "200", img: ChairImg },
		{ name: "Plants", amount: "80", img: PlantImg },
	];

	const renderShopData = () => {
		return itemArr?.map(data => (
			<Box
				key={data?.name}
				w="48%"
				mx="auto"
				boxShadow={"md"}
				my="5px"
				mb="0.5rem"
				borderRadius={"10px"}
				textAlign={"center"}
				p="10px"
				bg={syWhite}
			>
				<Box mb="10px">
					<Img src={data?.img} mx="auto" />
				</Box>
				<Text fontSize={"12px"} fontWeight={"700"} mb="10px">
					{data?.name}
				</Text>
				<Flex
					justifyContent={"center"}
					alignItems={"center"}
					bg={syThemeBgBlueColor}
					w="90%"
					boxShadow={"lg"}
					borderRadius={"50px"}
					textAlign={"center"}
					color={syWhite}
				>
					<Text fontSize={"12px"} fontWeight={"700"} mr="10px">
						{data?.amount}
					</Text>
					<Img src={rating} />
				</Flex>
			</Box>
		));
	};
	return (
		<Box p="10px">
			<Stack spacing={4} my="1rem" boxShadow={"md"}>
				<InputGroup>
					<InputLeftAddon bg="#fff">
						<IoIosSearch />
					</InputLeftAddon>
					<Input type="text" placeholder="Search Room Item" />
				</InputGroup>
			</Stack>
			<Text textAlign={"center"} fontWeight={"600"} mb="1rem">
				Popular Items
			</Text>
			<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
				{renderShopData()}
			</Flex>
		</Box>
	);
}

export default DreamShop;
