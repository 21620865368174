// Chakra Imports
import React, { useEffect, useState } from "react";
import {
	Box,
	Flex,
	Tab,
	Tabs,
	Heading,
	TabList,
	TabPanel,
	TabPanels,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import Spend from "./Spend";
import SaveUp from "./SaveUp";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";

// Api Services
import { getDashboardData } from "../../../services/userServices";

function DreamDashboard() {
	const toast = useToast();
	const height = useResponsiveHeight();
	const [loading, isloading] = useState(false);
	const [activeGoals, setActiveGoals] = useState([]);
	const [progressData, setProgressData] = useState({});
	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		fetchDashboardDetails();
	}, []);

	const fetchDashboardDetails = () => {
		isloading(true);
		let obj = {
			key: "string",
		};
		getDashboardData(obj)
			.then(res => {
				isloading(false);
				if (res?.result) {
					setProgressData(res?.data[0]?.data[2]?.widget[0]);
					setActiveGoals(res?.data[0]?.data[3]?.active_goal_plans);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isloading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	return (
		<Box
			minH={height}
			bg={syThemeBgBlueColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Flex
					alignContent={"center"}
					justifyContent={"center"}
					flexWrap={"wrap"}
					textAlign={"center"}
					mt="1rem"
				>
					<Heading
						fontSize={"22px"}
						fontWeight={"600"}
						color={syWhite}
					>
						My Dream Board
					</Heading>
				</Flex>
			</Box>
			<Box px="20px">
				<Tabs
					index={selectedIndex}
					onChange={index => setSelectedIndex(index)}
				>
					<TabList>
						<Tab
							textAlign={"center"}
							fontSize={"18px"}
							pl="0"
							w="50%"
							color={syWhite}
							_focus={{ boxShadow: "none" }}
							_selected={{
								color: syThemeBgYellowColor,
								fontWeight: 600,
								borderBottomColor: syThemeBgYellowColor,
							}}
						>
							Save Up
						</Tab>
						<Tab
							textAlign={"center"}
							fontSize={"18px"}
							w="50%"
							color={syWhite}
							_selected={{
								color: syThemeBgYellowColor,
								fontWeight: 600,
								borderBottomColor: syThemeBgYellowColor,
							}}
							_focus={{ boxShadow: "none" }}
						>
							Spend
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel px="0">
							<SaveUp
								progressData={progressData}
								activeGoals={activeGoals}
								loading={loading}
								selected={selectedIndex}
							/>
						</TabPanel>
						<TabPanel px="0">
							<Spend />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
				userRoute="/genz/user-profile"
			/>
		</Box>
	);
}

export default DreamDashboard;
