// Chakra React Imports
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import { Box, Flex, Img, Text, VStack, Heading } from "@chakra-ui/react";

// Component Imports
import calenderImg from "../../../images/genz/calender-white.png";
import {
	syBlack,
	syThemeBgYellowColor,
	syWhite,
} from "../../../constants/color";

const WeekCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [weekDates, setWeekDates] = useState([]);
	const [toggleCalender, setToggleCalender] = useState(false);

	// Function to get the week dates based on the selected date
	const getWeekDates = date => {
		const start = moment(date).startOf("week"); // Start of the week (Sunday)
		const dates = [];

		// Loop through the week and get each day's date
		for (let i = 0; i < 7; i++) {
			dates.push(moment(start).add(i, "days"));
		}
		return dates;
	};

	// Update the week dates when a new date is selected
	useEffect(() => {
		setWeekDates(getWeekDates(selectedDate));
	}, [selectedDate]);

	const isSelectedDate = date => moment(date).isSame(selectedDate, "day");

	const handleDateClick = date => {
		setSelectedDate(date.toDate()); // Update selectedDate with the clicked date
	};

	const handleCalendarChange = date => {
		setSelectedDate(date); // Update selectedDate with the calendar clicked date
	};
	const selectedMonthYear = moment(selectedDate).format("MMMM YYYY");

	return (
		<VStack align="center">
			<Box borderRadius="lg" w="100%">
				<Flex
					justifyContent={"space-between"}
					alignItems="center"
					my="1rem"
				>
					<Heading as="h3" size="md" mb={4} color={syWhite}>
						{selectedMonthYear}
					</Heading>
					<Img
						src={calenderImg}
						onClick={() => {
							setToggleCalender(!toggleCalender);
						}}
					/>
				</Flex>
				{toggleCalender ? (
					<Calendar
						onChange={handleCalendarChange}
						value={selectedDate}
						w="100%"
					/>
				) : null}
				<Flex justifyContent={"space-between"} mt="1rem">
					{weekDates.map((date, index) => (
						<Box>
							<Box
								key={index}
								p="10px"
								borderRadius={"30px"}
								textAlign={"center"}
								bg={
									isSelectedDate(date)
										? syThemeBgYellowColor
										: "#8f95f2"
								}
								color={
									isSelectedDate(date) ? syWhite : "inherit"
								}
								onClick={() => handleDateClick(date)}
								boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
							>
								<Text
									color={
										isSelectedDate(date) ? syBlack : syWhite
									}
									fontWeight={600}
								>
									{date.format("dd")}
									{/* First letter of the day and current date */}
								</Text>
								<Text color={syWhite}>
									{date.format("DD")}{" "}
								</Text>
							</Box>
							{isSelectedDate(date) ? (
								<Box
									w="10px"
									mt="3"
									mx="auto"
									borderRadius={"10px"}
									h="10px"
									bg={syThemeBgYellowColor}
								/>
							) : null}
						</Box>
					))}
				</Flex>
			</Box>
		</VStack>
	);
};

export default WeekCalendar;
