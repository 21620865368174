import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const fetchGoalsList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().goalList, data, config);
};

export const handleGoalStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().enableDisableGoal, data, config);
};

export const createGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalDetails, data, config);
};

export const updateGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().editGoalDetails, data, config);
};

export const uploadGoalHeadshot = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadGoalHeadshot, data, config);
};

export const fetchUserGoalList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().fetchUserGoal, data, config);
};

export const createGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createGoalPlan, data, config);
};

export const updateGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateGoalPlan, data, config);
};

export const fetchGoalRewardIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalRewardIcon, data, config);
};

export const createGoalEvent = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalEvent, data, config);
};

export const fetchResourceGoalListData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceListData, data, config);
};

export const fetchResourceList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceList, data, config);
};

export const fetchGoalListWithResource = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalListWithResource, data, config);
};

export const storeResourceIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().uploadGoalResourceIcon,
		data,
		config
	);
};

export const fetchSelectedGoalPlan = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().selectedGoalData, data, config);
};

// Reward Store Service

export const createRewardStoreItem = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().addNewStoreItem, data, config);
};

export const editRewardStoreItem = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().updateStoreItem, data, config);
};

export const handleStoreStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().manageStoreStatus, data, config);
};

export const fetchStoreRewardClass = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardClass, data, config);
};

export const fetchStoreRewardGroup = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardGroup, data, config);
};

export const fetchStoreRewardList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardList, data, config);
};
