// Chakra React Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Img,
	Flex,
	Text,
	Slider,
	Tooltip,
	Heading,
	Spinner,
	SliderMark,
	SliderTrack,
	SliderThumb,
	SliderFilledTrack,
} from "@chakra-ui/react";

// Component Imports
import WeekCalendar from "./WeekCalendar";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import graph from "../../../images/genz/dashboard/graph.png";
import upIcon from "../../../images/genz/dashboard/up-icon.png";
import {
	syBlack,
	syWhite,
	syBorderGrey,
	syArrowGreenColor,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";

function SaveUp({ progressData, activeGoals, loading, selected }) {
	const navigate = useNavigate();

	const renderActiveGoals = () => {
		if (activeGoals?.length) {
			return (
				<>
					<Text
						color={syThemeBgYellowColor}
						fontWeight={"700"}
						mb="4"
					>
						It’s time to Save !!
					</Text>
					{activeGoals?.map((data, index) => {
						return (
							<Box
								bg={
									data?.goal_readiness
										? syThemeBgYellowColor
										: "red.200"
								}
								p="10px"
								borderRadius={"10px"}
								mb="1.5rem"
								key={index}
								boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
								onClick={() => {
									let obj = {
										goal_uuid: data?.goal_type_uuid,
										goal_name: data?.goal_name,
										goal_reward_icon: data?.goal_reward_icon
											? data?.goal_reward_icon
											: "",
										goal_amount: data?.goal_amount,
										goal_tags: data?.prompt
											? data?.prompt.join(",")
											: [],
									};
									if (!data?.goal_readiness) {
										navigate("/genz/setup-goal", {
											state: {
												data: obj,
												goalPlanningId:
													data?.goal_planning_uuid,
											},
										});
									}
									if (data?.goal_readiness) {
										navigate("/genz/goal-event", {
											state: {
												data: data,
												goalPlanningId:
													data?.goal_planning_uuid,
											},
										});
									}
								}}
							>
								<Flex alignItems={"center"}>
									<Box position={"relative"} mr="1rem">
										<Img
											w="100px"
											h="100px"
											mx="auto"
											src={
												data?.goal_reward_icon
													? data?.goal_reward_icon
													: standImg
											}
										></Img>
										{data?.goal_reward_icon ? null : (
											<Box
												position={"absolute"}
												top="30px"
												left={"30%"}
											>
												<Img
													w="50%"
													src={lockImg}
												></Img>
											</Box>
										)}
									</Box>
									{data?.goal_readiness ? (
										<Box w="calc(100% - 120px)">
											<Text
												as="span"
												fontSize={"14px"}
												bg={syBlack}
												color={syWhite}
												px="5"
												borderRadius={"20px"}
											>
												{data?.goal_duration_days} days
												left
											</Text>
											<Heading
												fontSize={"20px"}
												fontWeight={"700"}
												fontStyle={"italic"}
												whiteSpace={"nowrap"}
												textOverflow={"ellipsis"}
												overflow={"hidden"}
												mb="3"
											>
												{data?.goal_name}
											</Heading>
											<Slider
												id="slider"
												defaultValue={
													data?.saved_amount
												}
												min={0}
												max={data?.goal_amount}
												colorScheme="blue"
												my="1rem"
												w="200px"
												isReadOnly
												ml="2"
											>
												{/* Slider Marks */}
												<SliderMark
													value={0}
													mt="4"
													ml="0"
													fontSize="12px"
													fontWeight="700"
												>
													$0
												</SliderMark>
												<SliderMark
													value={Math.round(
														data?.goal_amount / 4
													)}
													mt="4"
													ml="-2"
													fontSize="12px"
													fontWeight="700"
													textAlign="center"
												>
													$
													{Math.round(
														data?.goal_amount / 4
													)}
												</SliderMark>
												<SliderMark
													value={Math.round(
														data?.goal_amount / 2
													)}
													mt="4"
													ml="-2"
													fontSize="12px"
													fontWeight="700"
													textAlign="center"
												>
													$
													{Math.round(
														data?.goal_amount / 2
													)}
												</SliderMark>
												<SliderMark
													value={data?.goal_amount}
													mt="4"
													ml="-8"
													fontSize="12px"
													fontWeight="700"
													textAlign="center"
												>
													${data?.goal_amount}
												</SliderMark>

												{/* Slider Track */}
												<SliderTrack
													h="15px"
													borderRadius="10px"
												>
													<SliderFilledTrack />
												</SliderTrack>

												<SliderThumb>
													{selected === 0 ? (
														<Tooltip
															mb="1"
															className="demo"
															hasArrow
															bg="blue.500"
															color="white"
															fontSize={"xs"}
															placement="top"
															isOpen={true}
															label={`$${data?.saved_amount}`}
														>
															<Box />
														</Tooltip>
													) : null}
												</SliderThumb>
											</Slider>
										</Box>
									) : (
										<Box w="calc(100% - 120px)">
											<Heading
												fontSize={"20px"}
												textWrap="noWrap"
												whiteSpace={"noWrap"}
												textOverflow={"ellipsis"}
												overflow={"hidden"}
											>
												{data?.goal_name}
											</Heading>
											<Heading fontSize={"16px"} my="2">
												Goal Planning requires your
												attention...
											</Heading>
											<Heading fontSize={"16px"}>
												Please select and complete.
											</Heading>
										</Box>
									)}
								</Flex>
							</Box>
						);
					})}
				</>
			);
		} else {
			return (
				<Text
					p="10px"
					color={syBlack}
					bg={syThemeBgYellowColor}
					borderRadius={"10px"}
					fontWeight={"700"}
				>
					No Plan Available
				</Text>
			);
		}
	};

	return (
		<Box>
			<Box
				bg={syWhite}
				p="20px"
				borderRadius={"10px"}
				boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
			>
				<Flex justifyContent={"space-between"}>
					<Text color={syBorderGrey} fontWeight={"700"}>
						Total Syval Funds
					</Text>
					<Box>
						<Flex justifyContent={"flex-end"}>
							<Img src={upIcon} transform="rotate(-180deg)" />
							<Text fontWeight={"600"} color={syArrowGreenColor}>
								{progressData?.monthly_growth}
							</Text>
						</Flex>
						<Text fontSize={"12px"} fontWeight={"600"}>
							Compared to last month
						</Text>
					</Box>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Box mt="2rem">
						<Text
							color={syThemeBgBlueColor}
							fontSize={"26px"}
							fontWeight={"700"}
						>
							$ {progressData?.total_saving}
						</Text>
						<Text fontSize={"12px"} fontWeight={"700"}>
							You are doing amazing!!
						</Text>
					</Box>
					<Box>
						<Img src={graph} />
					</Box>
				</Flex>
			</Box>
			<Box my="2rem">
				<WeekCalendar />
			</Box>
			<Box mb="6rem">
				{loading ? (
					<Flex alignItems="center" justifyContent="center" mt="5rem">
						<Spinner size="xl" color={syWhite} />
					</Flex>
				) : (
					renderActiveGoals()
				)}
			</Box>
		</Box>
	);
}

export default SaveUp;
