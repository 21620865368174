// React Chakra Imports
import { Box, Img, Text } from "@chakra-ui/react";

// Component Imports
import FooterProfile from "../../common/FooterProfile";
import robot from "../../../images/genz/common-footer/help-robot.png";
import { syThemeBgGreenColor, syWhite } from "../../../constants/color";

const Help = () => {
	return (
		<Box
			bg={syThemeBgGreenColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
			minH="940px"
		>
			<Box>
				<Box w="50%" mx="auto">
					<Img src={robot} />
				</Box>
				<Box
					bg={syWhite}
					w="90%"
					mx="auto"
					mt="8"
					p="10px"
					borderRadius={"10px"}
					boxShadow="lg"
				>
					<Text mb="10px">
						Lorem Ipsum is simply dummy text of the printing and
						typesetting industry.{" "}
					</Text>
					<Text mb="10px">
						Lorem Ipsum is simply dummy text of the printing and
						typesetting industry.{" "}
					</Text>
					<Text>
						Lorem Ipsum is simply dummy text of the printing and
						typesetting industry.{" "}
					</Text>
				</Box>
				<FooterProfile
					position={"fixed"}
					bottom={"0px"}
					borderRadius="10px"
					userRoute="/genz/user-profile"
				/>
			</Box>
		</Box>
	);
};

export default Help;
