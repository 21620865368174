// React Chakra Imports
import React, { useEffect, useState } from "react";
import {
	Box,
	Img,
	Tabs,
	Tab,
	Text,
	Flex,
	Modal,
	Button,
	TabList,
	Slider,
	Heading,
	Spinner,
	TabPanel,
	useToast,
	TabPanels,
	ModalBody,
	SliderMark,
	SliderThumb,
	SliderTrack,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	ModalCloseButton,
	SliderFilledTrack,
} from "@chakra-ui/react";

// Component Imports
import moment from "moment";
import DreamShop from "./DreamShop";
import { useNavigate } from "react-router-dom";
import DreamLifeSavedEvent from "./DreamLifeSavedEvent";
import StandImg from "../../../../images/genz/goal/stand.png";
import { getUserName } from "../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import DreamLifeCard from "../../../../images/genz/goal/dreamlife/card.png";
import DreamLifeAward from "../../../../images/genz/goal/dreamlife/award.png";
import DreamLifeBgImg from "../../../../images/genz/goal/dreamlife/dreamLifeBg.png";
import DreamCompleteGiftImg from "../../../../images/genz/goal/dreamlife/gift.png";
import {
	syWhite,
	syBlack,
	syBorderGrey,
	syArrowGreenColor,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../../constants/color";

// Api Imports
import {
	getDreamLifeDashboardData,
	getDreamLifeDashboardActiveData,
} from "../../../../services/userServices";

function DreamLife({ tabValue }) {
	const toast = useToast();
	const navigate = useNavigate();
	const userName = getUserName();
	const [loading, isloading] = useState(false);
	const [goalsToday, setGoalsToday] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [activeDetails, setActiveDetails] = useState([]);
	const [completedGoals, setCompletedGoals] = useState([]);
	const [modalOpenCount, setModalOpenCount] = useState(0);
	const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
	const [completedGoalData, setCompletedGoalData] = useState({});
	const {
		isOpen: isSecondOpen,
		onOpen: onSecondOpen,
		onClose: onSecondClose,
	} = useDisclosure();

	useEffect(() => {
		if (tabValue === "dream") {
			fetchDashboardDetails();
			fetchDashboardActiveDetails();
		}
	}, [tabValue]);

	const fetchDashboardDetails = () => {
		isloading(true);
		let obj = {
			key: "string",
		};
		getDreamLifeDashboardData(obj)
			.then(res => {
				isloading(false);
				setCompletedGoals(
					res?.data?.[0]?.data?.[2]?.completed_goal_plans
				);
				toast(toastFunctionToaster(res?.message, "success"));
			})
			.catch(err => {
				if (err) {
					isloading(false);
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};

	const fetchDashboardActiveDetails = () => {
		let obj = {
			key: "string",
		};
		getDreamLifeDashboardActiveData(obj)
			.then(res => {
				toast(toastFunctionToaster(res?.message, "success"));
				setActiveDetails(res?.data[0]?.data?.[0]?.active_goal_plans);
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};

	const navigateToGoalEvent = data => {
		navigate("/genz/goal-event", {
			state: {
				data: data,
				goalPlanningId: data?.goal_planning_uuid,
			},
		});
	};

	function renderCompletedGoal() {
		if (completedGoals && completedGoals.length > 0) {
			var completedGoalResult = completedGoals.map((data, index) => (
				<Box
					w="32%"
					mr="1%"
					mb="1.5rem"
					h="85px"
					onClick={() => {
						// navigateToGoalEvent(data);
						onOpen();
						setCompletedGoalData(data);
					}}
				>
					<Img
						h="100%"
						w={"100%"}
						p="5px"
						border={"3px"}
						borderStyle={"dotted"}
						borderRadius={"10px"}
						borderColor={syArrowGreenColor}
						src={
							data?.goal_reward_icon
								? data?.goal_reward_icon
								: StandImg
						}
					/>
					<Text
						fontSize={"12px"}
						fontWeight={"600"}
						textAlign={"center"}
					>
						{data?.goal_name}
					</Text>
				</Box>
			));
			return completedGoalResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text fontSize={16} color={syThemeBgYellowColor}>
						{"No Goals Found!!"}
					</Text>
				</Box>
			);
		}
	}

	function renderActiveGoal() {
		if (activeDetails && activeDetails.length > 0) {
			var activeGoalResult = activeDetails.map((data, index) => (
				<Box
					w="32%"
					mr="1%"
					mb="1.5rem"
					h="85px"
					onClick={() => {
						navigateToGoalEvent(data);
					}}
				>
					<Img
						h="100%"
						w={"100%"}
						border={"3px"}
						borderStyle={"dotted"}
						borderRadius={"10px"}
						borderColor={syBorderGrey}
						p="5px"
						src={
							data?.goal_reward_icon
								? data?.goal_reward_icon
								: StandImg
						}
					/>
					<Text
						fontSize={"12px"}
						fontWeight={"600"}
						textAlign={"center"}
					>
						{data?.goal_name}
					</Text>
				</Box>
			));
			return activeGoalResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Active Goals Found!!"}
					</Text>
				</Box>
			);
		}
	}

	// Update the useEffect that filters the goals
	useEffect(() => {
		const today = moment().format("YYYY-MM-DD");
		// Filter the goals with target_completion_date on or after today
		const goalsForToday = completedGoals.filter(goal => {
			const goalCompletionDate = moment(goal.target_completion_date);
			return goalCompletionDate.isSameOrAfter(moment(today), "day");
		});
		setGoalsToday(goalsForToday);

		if (goalsForToday.length > 0) {
			onSecondOpen();
			setModalOpenCount(0);
		}
	}, [completedGoals, onOpen, onSecondOpen]);

	const handleNextGoal = () => {
		console.log(modalOpenCount);
		if (modalOpenCount <= 1) {
			onSecondClose();
		}
		setModalOpenCount(modalOpenCount + 1);
		// If the count is less than 2 (0, 1) show next goal
		if (modalOpenCount < 2) {
			if (currentGoalIndex < goalsToday.length - 1) {
				setCurrentGoalIndex(currentGoalIndex + 1);
			}
		} else {
			onSecondClose();
		}
	};

	return (
		<Box bg={syWhite} borderRadius={"10px"} mb="2rem">
			<Box px="10px" pt="30px">
				<Text textAlign={"center"} fontWeight="700">
					{userName} DreamLife
				</Text>
				<Box position={"relative"} mt="3">
					<Img src={DreamLifeBgImg} />
					<Box position={"absolute"} bottom="30%" right={"30%"}>
						<Img src={DreamLifeAward} />
					</Box>
					<Box position={"absolute"} bottom="20%" left={"95px"}>
						<Img src={DreamLifeCard} />
					</Box>
				</Box>
			</Box>
			<Text
				textAlign={"center"}
				fontWeight={"600"}
				textDecoration={"underline"}
			>
				Edit
			</Text>
			{loading ? (
				<Flex alignItems="center" h="30vh" justifyContent="center">
					<Spinner size="lg" color={syBlack} />
				</Flex>
			) : (
				<Tabs w="95%" mx="auto">
					<TabList>
						<Tab
							w="50%"
							_selected={{
								fontWeight: 600,
								color: syThemeBgBlueColor,
								borderBottomColor: syThemeBgBlueColor,
							}}
						>
							Rewards
						</Tab>
						<Tab
							w="50%"
							_selected={{
								fontWeight: 600,
								color: syThemeBgBlueColor,
								borderBottomColor: syThemeBgBlueColor,
							}}
						>
							Shop
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel p="0px">
							<Box pt="20px">
								<Text
									textAlign={"center"}
									fontWeight={"600"}
									color={syThemeBgBlueColor}
									mb="0.5rem"
								>
									Achievements
								</Text>
								<Flex
									flexWrap={"wrap"}
									alignItems={"center"}
									p="10px"
								>
									{renderCompletedGoal()}
								</Flex>
								<Text
									textAlign={"center"}
									fontWeight={"600"}
									color={syThemeBgBlueColor}
									my="0.5rem"
								>
									Work’n on it!!
								</Text>
								<Flex
									flexWrap={"wrap"}
									alignItems={"center"}
									p="10px"
								>
									{renderActiveGoal()}
								</Flex>
							</Box>
						</TabPanel>
						<TabPanel p="0px">
							<DreamShop />
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
			<DreamLifeSavedEvent
				completedData={completedGoalData}
				open={isOpen}
				onClose={onClose}
			/>
			{/* Achievement Unlocked Modal */}
			<Modal
				isCentered
				isOpen={isSecondOpen}
				onClose={() => {
					handleNextGoal();
				}}
			>
				<ModalOverlay />
				<ModalContent m="5" borderRadius="10px" bg={syThemeBgBlueColor}>
					<ModalCloseButton top="15px" left="7px" bg="#fff" />
					<Heading
						fontSize={"20px"}
						color={syThemeBgYellowColor}
						pt="15px"
						textAlign={"center"}
					>
						New Achievements
					</Heading>

					<ModalBody
						m="4"
						bg="#eff0fd"
						borderRadius={"10px"}
						p="10px"
						py="20px"
						opacity={1}
					>
						<Box>
							<Box position={"relative"}>
								<Img src={DreamCompleteGiftImg} mx="auto" />
								<Box
									transform="translate(-50%, -50%)"
									position={"absolute"}
									top="55%"
									left="50%"
								>
									<Img
										w="40%"
										mx="auto"
										borderRadius={"10px"}
										src={
											goalsToday[currentGoalIndex]
												?.goal_reward_icon
										}
									/>
								</Box>
							</Box>
							<Heading
								fontSize={"20px"}
								textAlign={"center"}
								my="1rem"
								color={syBlack}
							>
								{" "}
								{goalsToday[currentGoalIndex]?.goal_name}
							</Heading>
							<Flex
								justifyContent={"center"}
								bg={syBlack}
								color={syWhite}
								width={"fit-content"}
								mx="auto"
								px="4"
								borderRadius={"50px"}
							>
								<Text fontSize={"14px"}>
									{moment(
										goalsToday[currentGoalIndex]
											?.target_completion_date
									)
										.format("MMMM")
										.slice(0, 4)}{" "}
									{moment().format("D")}
								</Text>{" "}
								-
								<Text fontSize={"14px"}>
									{moment().format("MMMM").slice(0, 4)}{" "}
									{moment().format("D")}
								</Text>
							</Flex>
							<Box w="90%" mx="auto">
								<Slider
									id="slider"
									defaultValue={
										goalsToday[currentGoalIndex]
											?.goal_amount
									}
									min={0}
									max={
										goalsToday[currentGoalIndex]
											?.goal_amount
									}
									colorScheme="blue"
									my="1rem"
									w="100%"
									isReadOnly
								>
									{/* Slider Marks */}
									<SliderMark
										value={0}
										mt="4"
										ml="0"
										mx="auto"
										fontSize="12px"
										fontWeight="700"
									>
										$0
									</SliderMark>
									<SliderMark
										value={Math.round(
											goalsToday[currentGoalIndex]
												?.goal_amount / 4
										)}
										mt="4"
										ml="-2"
										fontSize="12px"
										fontWeight="700"
										textAlign="center"
									>
										$
										{Math.round(
											goalsToday[currentGoalIndex]
												?.goal_amount / 4
										)}
									</SliderMark>
									<SliderMark
										value={Math.round(
											goalsToday[currentGoalIndex]
												?.goal_amount / 2
										)}
										mt="4"
										ml="-2"
										fontSize="12px"
										fontWeight="700"
										textAlign="center"
									>
										$
										{Math.round(
											goalsToday[currentGoalIndex]
												?.goal_amount / 2
										)}
									</SliderMark>
									<SliderMark
										value={
											goalsToday[currentGoalIndex]
												?.goal_amount
										}
										mt="4"
										ml="-8"
										fontSize="12px"
										fontWeight="700"
										textAlign="center"
									>
										$
										{
											goalsToday[currentGoalIndex]
												?.goal_amount
										}
									</SliderMark>

									{/* Slider Track */}
									<SliderTrack h="15px" borderRadius="10px">
										<SliderFilledTrack />
									</SliderTrack>

									<SliderThumb></SliderThumb>
								</Slider>
							</Box>
							<Box textAlign={"center"} mt="1.5rem">
								<Button
									borderRadius={"30px"}
									boxShadow={"md"}
									color={syThemeBgBlueColor}
									bg={syThemeBgYellowColor}
									_hover={{ boxShadow: "none" }}
									onClick={() => {
										handleNextGoal();
									}}
								>
									Add to my dream life
								</Button>
							</Box>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default DreamLife;
